<template>
  <a-drawer
    :body-style="isUpdate ? {} : { paddingTop: '8px' }"
    :destroy-on-close="true"
    :title="title"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <input-form
      v-if="isUpdate"
      :detail="detail"
      :is-update="isUpdate"
      :label-col="labelCol"
      :source-type="sourceType"
      :wrapper-col="wrapperCol"
      :isClone="isClone"
      :deviceType="deviceType"
      :isSimple="isSimple"
      @ok="ok"
      @cancel="closeDrawer"
    ></input-form>
    <a-tabs v-else v-model="activeKey">
      <a-tab-pane key="manual" tab="手动创建">
        <a-alert
          message="默认通过 SNMP 监控。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        ></a-alert>

        <input-form
          :detail="detail"
          :is-update="isUpdate"
          :label-col="labelCol"
          :source-type="sourceType"
          :wrapper-col="wrapperCol"
          :deviceType="deviceType"
          :isSimple="isSimple"
          @ok="ok"
          @cancel="closeDrawer"
        ></input-form>
      </a-tab-pane>
      <!-- <a-tab-pane key="scan" tab="SNMP 扫描">
        <scan-form
          :label-col="labelCol"
          :source-type="sourceType"
          :wrapper-col="wrapperCol"
          @ok="ok"
          @cancel="closeDrawer"
        ></scan-form>
      </a-tab-pane> -->
      <a-tab-pane
        v-if="enableImport"
        key="import"
        tab="文件导入"
      >
        <file-import-form
          :label-col="labelCol"
          :source-type="sourceType"
          :wrapper-col="wrapperCol"
          @ok="ok"
          @changeImportStatus="changeImportStatus"
          @cancel="closeDrawer"
        ></file-import-form>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import { getNetworkDevice } from '@/api/network-device'
import { getServer } from '@/api/server'
import { getStorage } from '@/api/storage'
import FileImportForm from './modules/FileImportForm.vue'
import InputForm from './modules/InputForm.vue'
// import ScanForm from './modules/ScanForm'

export default {
  name: 'HardwareInputDrawer',
  components: {
    FileImportForm,
    InputForm
    // ScanForm
  },
  props: {
    sourceType: {
      type: String,
      required: true
    },
    deviceType: {
      type: [String, Array]
    },
    isSimple: {
      type: Boolean
    }
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      isClone: false,
      labelCol: {
        span: 4,
        style: {
          textAlign: 'left'
        }
      },
      wrapperCol: {
        span: 20
      },
      detail: {},
      activeKey: 'manual',
      importDone: true
    }
  },
  computed: {
    enableImport () {
      if (this.sourceType === 'network_device') {
        return this.deviceType === 'switch'
      } else {
        return true
      }
    },
    title () {
      if (this.isClone) {
        return this.$t(`source_type.${this.sourceType}`) + '克隆'
      } else if (this.isUpdate) {
        return this.$t(`source_type.${this.sourceType}`) + '编辑'
      } else return ''
    },
    getFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return getNetworkDevice
        case 'storage':
          return getStorage
        case 'server':
          return getServer
        default:
          return undefined
      }
    }
  },
  methods: {
    show (id, isClone = false) {
      if (id) {
        this.isUpdate = true
        this.isClone = isClone
        this.getFunc(id).then(res => {
          this.detail = res.data
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isClone = isClone
        this.isUpdate = false
        this.visible = true
      }
    },
    reset () {
      this.isClone = false
      this.isUpdate = false
      this.detail = {}
      this.activeKey = 'manual'
      this.visible = false
    },
    closeDrawer () {
      if (this.activeKey === 'import') {
        if (this.importDone) {
          this.reset()
        } else {
          this.$message.warn('请等待导入完成...')
        }
      } else this.reset()
    },
    ok () {
      this.$emit('ok')
      this.activeKey = 'manual'
      this.visible = false
    },
    changeImportStatus (v) {
      this.importDone = v
    }
  }
}
</script>

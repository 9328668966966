<template>
  <a-form-model ref="form" :label-col="labelCol" :model="form" :rules="rules">
    <a-divider
      v-if="isUpdate"
      orientation="left"
      style="margin-top: 0"
    >
      基本设置
    </a-divider>
    <a-form-model-item
      label="名称"
      prop="name"
      :wrapper-col="wrapperCol"
    >
      <a-input v-model="form.name" :max-length="128"></a-input>
    </a-form-model-item>
    <a-form-model-item prop="groups" :wrapper-col="wrapperCol">
      <group-select
        v-model="form.groups"
        :source-type="sourceType"
      ></group-select>
      <question-icon
        slot="label"
        title="所属群组"
        description="用于分组查询及告警配置"
      ></question-icon>
    </a-form-model-item>
    <a-form-model-item
      v-if="sourceType === 'network_device'"
      label="设备类型"
      prop="deviceType"
      :rules="[
        {
          message: '请选择设备类型',
          required: true,
          trigger: 'change'
        }
      ]"
      :wrapper-col="wrapperCol"
    >
      <a-select
        v-model="form.deviceType"
        :disabled="isSimple || isUpdate"
        :options="selectOptions"
      ></a-select>
    </a-form-model-item>
    <a-form-model-item label="制造商" prop="vendor" :wrapper-col="wrapperCol">
      <a-select
        v-model="form.vendor"
        :options="options.vendors"
        @change="form.template = undefined"
      ></a-select>
    </a-form-model-item>
    <a-form-model-item label="代理" prop="proxyId" :wrapper-col="wrapperCol">
      <a-select
        v-model="form.proxyId"
        :options="options.proxies"
      ></a-select>
    </a-form-model-item>
    <template v-if="sourceType === 'network_device' ? form.deviceType && form.vendor : form.vendor">
      <a-form-model-item
        label="监控模板"
        prop="template"
        :wrapper-col="wrapperCol"
      >
        <template-select
          v-model="form.template"
          :source-type="sourceType"
          :device-type="form.deviceType"
          :vendor="form.vendor"
        ></template-select>
      </a-form-model-item>
    </template>
    <a-form-model-item
      label="监控地址"
      :wrapper-col="wrapperCol"
      style="margin-bottom: 0"
    >
      <div style="display: flex">
        <a-form-model-item prop="monitorAddress">
          <a-input v-model="form.monitorAddress" :max-length="128"></a-input>
        </a-form-model-item>
        <span style="margin: 0 4px">:</span>
        <a-form-model-item prop="monitorPort">
          <a-input
            v-model="form.monitorPort"
            :max="65535"
            :min="1"
            type="number"
          ></a-input>
        </a-form-model-item>
      </div>
    </a-form-model-item>
    <!--    SNMP-->
    <a-form-model-item
      label="SNMP 版本"
      prop="snmpVersion"
      :wrapper-col="wrapperCol"
    >
      <a-select v-model="form.snmpVersion" :options="options.snmpVersion"></a-select>
    </a-form-model-item>
    <template v-if="form.snmpVersion !== 'snmp_v3'">
      <a-form-model-item
        label="SNMP 团体名"
        prop="snmpCommunity"
        :rules="[
          {
            message: '请输入团体名',
            required: true,
            trigger: 'blur'
          }
        ]"
        :wrapper-col="wrapperCol"
      >
        <a-input-password
          v-model="form.snmpCommunity"
          :max-length="128"
          :visibility-toggle="!isUpdate"
        ></a-input-password>
      </a-form-model-item>
    </template>
    <template v-else>
      <a-form-model-item
        label="上下文名称"
        prop="snmpContextName"
        :wrapper-col="wrapperCol"
      >
        <a-input v-model="form.snmpContextName" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="安全名称"
        prop="snmpSecurityName"
        :wrapper-col="wrapperCol"
      >
        <a-input v-model="form.snmpSecurityName" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="安全级别"
        prop="snmpSecurityLevel"
        :wrapper-col="wrapperCol"
      >
        <a-select v-model="form.snmpSecurityLevel" :options="options.snmpSecurityLevel"></a-select>
      </a-form-model-item>
      <template v-if="form.snmpSecurityLevel !== 'no_auth_no_priv'">
        <a-form-model-item
          label="验证协议"
          prop="snmpAuthProtocol"
          :wrapper-col="wrapperCol"
        >
          <a-select v-model="form.snmpAuthProtocol" :options="options.snmpAuthProtocol"></a-select>
        </a-form-model-item>
        <a-form-model-item
          label="验证协议密码"
          prop="snmpAuthPassphrase"
          :wrapper-col="wrapperCol"
        >
          <a-input-password
            v-model="form.snmpAuthPassphrase"
            :max-length="128"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
        <template v-if="form.snmpSecurityLevel === 'auth_priv'">
          <a-form-model-item
            label="隐私协议"
            prop="snmpPrivacyProtocol"
            :wrapper-col="wrapperCol"
          >
            <a-select v-model="form.snmpPrivacyProtocol" :options="options.snmpPrivacyProtocol"></a-select>
          </a-form-model-item>
          <a-form-model-item
            label="隐私协议密码"
            prop="snmpPrivacyPassphrase"
            :wrapper-col="wrapperCol"
          >
            <a-input-password
              v-model="form.snmpPrivacyPassphrase"
              :max-length="128"
              :visibility-toggle="!isUpdate"
            ></a-input-password>
          </a-form-model-item>
        </template>
      </template>
    </template>
    <a-form-model-item
      label="使用批量请求"
      prop="snmpUseBulk"
      :wrapper-col="wrapperCol"
    >
      <a-switch v-model="form.snmpUseBulk"></a-switch>
    </a-form-model-item>
    <template v-if="isUpdate && !isClone">
      <a-divider orientation="left" style="margin-top: 0">
        资产信息
      </a-divider>
      <a-form-model-item label="SN" prop="serialNumber" :wrapper-col="wrapperCol">
        <a-input v-model="form.serialNumber" :max-length="128"></a-input>
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-model-item
            label="采购日期"
            :label-col="{
              span: 8,
              style: {
                textAlign: 'left'
              }
            }"
            prop="purchasedAt"
            :wrapper-col="{ span: 16 }"
          >
            <a-date-picker
              v-model="form.purchasedAt"
              placeholder=""
              style="width: 100%"
            ></a-date-picker>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            label="过保日期"
            :label-col="{
              span: 8,
              style: {
                textAlign: 'left'
              }
            }"
            prop="expiresAt"
            :wrapper-col="{ span: 16 }"
          >
            <a-date-picker
              v-model="form.expiresAt"
              placeholder=""
              style="width: 100%"
            ></a-date-picker>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="采购金额" prop="price" :wrapper-col="wrapperCol">
        <a-input
          v-model="form.price"
          :min="0"
          suffix="元"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="manager" :wrapper-col="wrapperCol">
        <a-input v-model="form.manager" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="位置" prop="location" :wrapper-col="wrapperCol">
        <a-input v-model="form.location" :max-length="128"></a-input>
      </a-form-model-item>
    </template>
    <a-form-model-item
      :wrapper-col="{ span: 20, offset: 4 }"
      style="margin-bottom: 0"
    >
      <a-space size="middle">
        <a-button :loading="loading.confirm" type="primary" @click="ok">
          确定
        </a-button>
        <a-button @click="cancel">取消</a-button>
        <a-button
          v-if="!monitorActiveMode && !isUpdate || isClone"
          :loading="loading.test"
          type="primary"
          @click="test"
          style="background: #ffa040; border-color: #ffa040"
        >
          测试
        </a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { getHardwareVendorList } from '@/api/base'
import { getMonitorProxyList } from '@/api/monitor'
import {
  createNetworkDevice,
  testNetworkDevice,
  updateNetworkDevice
} from '@/api/network-device'
import {
  createServer,
  testServer,
  updateServer
} from '@/api/server'
import {
  createStorage,
  testStorage,
  updateStorage
} from '@/api/storage'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'
import TemplateSelect from '@/components/select/TemplateSelect.vue'
import {
  networkDeviceTypes,
  snmpAuthProtocols,
  snmpPrivacyProtocols,
  snmpSecurityLevels,
  snmpVersions,
  securityType
} from '@/utils/const'

export default {
  name: 'InputForm',
  components: {
    GroupSelect,
    QuestionIcon,
    TemplateSelect
  },
  props: {
    detail: {
      type: Object
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isClone: {
      type: Boolean
    },
    labelCol: {
      type: Object
    },
    sourceType: {
      type: String,
      required: true
    },
    wrapperCol: {
      type: Object
    },
    deviceType: {
      type: [String, Array]
    },
    isSimple: {
      type: Boolean
    }
  },
  data () {
    return {
      form: this.isUpdate
        ? {
          id: this.detail.id,
          name: this.detail.name,
          groups: this.detail.groups,
          deviceType: this.detail.device_type,
          vendor: this.detail.vendor,
          template: this.detail.template,
          monitorAddress: this.detail.monitor_address,
          monitorPort: this.detail.monitor_port,
          snmpVersion: this.detail.snmp_version,
          snmpCommunity: this.detail.snmp_community,
          snmpContextName: this.detail.snmp_context_name,
          snmpSecurityName: this.detail.snmp_security_name,
          snmpSecurityLevel: this.detail.snmp_security_level,
          snmpAuthProtocol: this.detail.snmp_auth_protocol,
          snmpAuthPassphrase: this.detail.snmp_auth_passphrase,
          snmpPrivacyProtocol: this.detail.snmp_privacy_protocol,
          snmpPrivacyPassphrase: this.detail.snmp_privacy_passphrase,
          snmpUseBulk: this.detail.snmp_use_bulk,
          serialNumber: this.detail.serial_number,
          purchasedAt: this.detail.purchased_at ? moment(this.detail.purchased_at, 'YYYY-MM-DD') : undefined,
          expiresAt: this.detail.expires_at ? moment(this.detail.expires_at, 'YYYY-MM-DD') : undefined,
          price: this.detail.price,
          manager: this.detail.manager,
          location: this.detail.location,
          proxyId: this.detail.proxy_id
        }
        : {
          id: '',
          name: '',
          groups: undefined,
          vendor: undefined,
          template: undefined,
          deviceType: Array.isArray(this.deviceType) ? this.deviceType[0] : this.deviceType,
          monitorAddress: '',
          monitorPort: 161,
          snmpVersion: 'snmp_v2c',
          snmpCommunity: 'public',
          snmpContextName: '',
          snmpSecurityName: '',
          snmpSecurityLevel: undefined,
          snmpAuthProtocol: undefined,
          snmpAuthPassphrase: '',
          snmpPrivacyProtocol: undefined,
          snmpPrivacyPassphrase: '',
          snmpUseBulk: false,
          serialNumber: '',
          purchasedAt: undefined,
          expiresAt: undefined,
          price: undefined,
          manager: '',
          location: '',
          proxyId: undefined
        },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        vendor: [
          {
            message: '请选择制造商',
            required: true,
            trigger: 'change'
          }
        ],
        template: [
          {
            message: '请选择监控模板',
            required: true,
            trigger: 'change'
          }
        ],
        monitorAddress: [
          {
            message: '请输入 IP 地址',
            required: true,
            trigger: 'blur'
          }
        ],
        monitorPort: [
          {
            message: '请输入监控端口',
            required: true,
            trigger: 'blur'
          }
        ],
        snmpVersion: [
          {
            message: '请选择 SNMP 版本',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      options: {
        proxies: [],
        securityType: securityType.map(value => {
          return {
            title: this.$t(`network_device_type.${value}`),
            value
          }
        }),
        deviceType: networkDeviceTypes.map(value => {
          return {
            title: this.$t(`network_device_type.${value}`),
            value
          }
        }),
        vendors: [],
        snmpVersion: snmpVersions.map(value => {
          return {
            title: this.$t(`snmp_version.${value}`),
            value
          }
        }),
        snmpSecurityLevel: snmpSecurityLevels.map(value => {
          return {
            title: this.$t(`snmp_security_level.${value}`),
            value
          }
        }),
        snmpAuthProtocol: snmpAuthProtocols.map(value => {
          return {
            title: this.$t(`snmp_auth_protocol.${value}`),
            value
          }
        }),
        snmpPrivacyProtocol: snmpPrivacyProtocols.map(value => {
          return {
            title: this.$t(`snmp_privacy_protocol.${value}`),
            value
          }
        })
      },
      loading: {
        confirm: false,
        test: false
      }
    }
  },
  computed: {
    ...mapGetters(['monitorActiveMode']),
    createFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return createNetworkDevice
        case 'storage':
          return createStorage
        case 'server':
          return createServer
        default:
          return undefined
      }
    },
    updateFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return updateNetworkDevice
        case 'storage':
          return updateStorage
        case 'server':
          return updateServer
        default:
          return undefined
      }
    },
    testFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return testNetworkDevice
        case 'storage':
          return testStorage
        case 'server':
          return testServer
        default:
          return undefined
      }
    },
    selectOptions () {
      if (Array.isArray(this.deviceType)) return this.options.securityType
      return this.options.deviceType
    }
  },
  mounted () {
    let product = ''
    if (this.sourceType === 'network_device') {
      if (this.form.deviceType === 'switch') product = 'switch'
      else if (this.form.deviceType === 'access_controller') product = 'access_controller'
      else product = 'security'
    } else {
      product = this.sourceType
    }
    getHardwareVendorList({ product }).then(res => {
      this.options.vendors = res.data.data.map(item => {
        return {
          title: item.name,
          value: item.key
        }
      })
    })
    getMonitorProxyList().then(res => {
      this.options.proxies = res.data.data.map(item => {
        return {
          title: item.name,
          value: item.id
        }
      })
    })
  },
  methods: {
    reset () {
      this.form = {
        id: '',
        name: '',
        groups: undefined,
        deviceType: undefined,
        vendor: undefined,
        template: undefined,
        monitorAddress: '',
        monitorPort: 161,
        snmpVersion: 'snmp_v2c',
        snmpCommunity: 'public',
        snmpContextName: '',
        snmpSecurityName: '',
        snmpSecurityLevel: undefined,
        snmpAuthProtocol: undefined,
        snmpAuthPassphrase: '',
        snmpPrivacyProtocol: undefined,
        snmpPrivacyPassphrase: '',
        snmpUseBulk: false,
        serialNumber: '',
        purchasedAt: undefined,
        expiresAt: undefined,
        price: undefined,
        manager: '',
        location: ''
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            vendor: this.form.vendor,
            template_id: this.form.template.id,
            monitor_address: this.form.monitorAddress,
            monitor_port: this.form.monitorPort,
            snmp_version: this.form.snmpVersion,
            snmp_use_bulk: this.form.snmpUseBulk
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (this.form.proxyId) {
            params.proxy_id = this.form.proxyId
          }
          if (this.form.snmpVersion === 'snmp_v3') {
            params.snmp_context_name = this.form.snmpContextName
            params.snmp_security_name = this.form.snmpSecurityName
            params.snmp_security_level = this.form.snmpSecurityLevel
            if (this.form.snmpSecurityLevel !== 'no_auth_no_priv') {
              params.snmp_auth_protocol = this.form.snmpAuthProtocol
              params.snmp_auth_passphrase = this.form.snmpAuthPassphrase
              if (this.form.snmpSecurityLevel === 'auth_priv') {
                params.snmp_privacy_protocol = this.form.snmpPrivacyProtocol
                params.snmp_privacy_passphrase = this.form.snmpPrivacyPassphrase
              }
            }
          } else {
            params.snmp_community = this.form.snmpCommunity
          }

          this.loading.confirm = true
          if (this.isUpdate && !this.isClone) {
            if (this.form.serialNumber) params.serial_number = this.form.serialNumber
            if (this.form.purchasedAt) params.purchased_at = this.form.purchasedAt.format('YYYY-MM-DD')
            if (this.form.expiresAt) params.expires_at = this.form.expiresAt.format('YYYY-MM-DD')
            if (this.form.price) params.price = this.form.price
            if (this.form.manager) params.manager = this.form.manager
            if (this.form.location) params.location = this.form.location
            this.updateFunc(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading.confirm = false
              })
          } else {
            if (this.sourceType === 'network_device') {
              params.device_type = this.form.deviceType
            }
            this.createFunc(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading.confirm = false
              })
          }
        }
      })
    },
    test () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            monitor_address: this.form.monitorAddress,
            monitor_port: this.form.monitorPort,
            snmp_version: this.form.snmpVersion,
            snmp_community: this.form.snmpCommunity,
            snmp_context_name: this.form.snmpContextName,
            snmp_security_name: this.form.snmpSecurityName,
            snmp_security_level: this.form.snmpSecurityLevel,
            snmp_auth_protocol: this.form.snmpAuthProtocol,
            snmp_auth_passphrase: this.form.snmpAuthPassphrase,
            snmp_privacy_protocol: this.form.snmpPrivacyProtocol,
            snmp_privacy_passphrase: this.form.snmpPrivacyPassphrase
          }
          this.loading.test = true
          this.testFunc(params)
            .then(res => {
              this.$message.success(res.message)
            })
            .finally(() => {
              this.loading.test = false
            })
        }
      })
    },
    cancel () {
      this.reset()
      this.$emit('cancel')
    }
  },
  watch: {
    'form.deviceType' () {
      this.form.template = undefined
    },
    'form.vendor' () {
      this.form.template = undefined
    },
    'form.snmpVersion' (newVal, oldVal) {
      if (newVal === 'snmp_v3') {
        this.form.snmpCommunity = undefined
      } else if (oldVal === 'snmp_v3') {
        this.form.snmpContextName = undefined
        this.form.snmpSecurityName = undefined
        this.form.snmpSecurityLevel = undefined
        this.form.snmpAuthProtocol = undefined
        this.form.snmpAuthPassphrase = undefined
        this.form.snmpPrivacyProtocol = undefined
        this.form.snmpPrivacyPassphrase = undefined
      }
    }
  }
}
</script>

<template>
  <a-select
    :allow-clear="false"
    :disabled="disabled"
    :filter-option="false"
    :label-in-value="true"
    :options="options"
    :show-search="true"
    :value="selected"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getNetworkDeviceTemplateList } from '@/api/network-device'
import { getStorageTemplateList } from '@/api/storage'
import { getServerTemplateList } from '@/api/server'

export default {
  name: 'TemplateSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    sourceType: {
      type: String,
      required: true
    },
    deviceType: {
      type: String
    },
    vendor: {
      type: String
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 20
      },
      selected: undefined,
      options: [],
      total: 0,
      timer: null
    }
  },
  computed: {
    getListFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return getNetworkDeviceTemplateList
        case 'storage':
          return getStorageTemplateList
        case 'server':
          return getServerTemplateList
        default:
          return undefined
      }
    }
  },
  mounted () {
    if (this.value) {
      this.selected = {
        key: this.value.id,
        label: this.value.name
      }
    }
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      if (this.vendor) params.vendor = this.vendor
      if (this.deviceType) params.device_type = this.deviceType
      this.getListFunc(params).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(source => {
          if (this.options.filter(option => option.value === source.id).length === 0) {
            this.options.push({
              key: source.id,
              label: source.name
            })
          }
        })
        if (!this.value && this.vendor) this.selected = this.options[0]
      })
    },
    change (value) {
      this.$emit('input', {
        id: value.key,
        name: value.label
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    }
  },
  watch: {
    sourceType () {
      this.options = []
      this.fetch()
    },
    deviceType () {
      this.options = []
      this.fetch()
    },
    vendor () {
      this.options = []
      this.fetch()
    },
    value: {
      handler (v) {
        if (v) {
          this.selected = {
            key: v.id,
            label: v.name
          }
        } else {
          this.selected = undefined
        }
      },
      deep: true
    }
  }
}
</script>
